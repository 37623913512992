<template>
  <transition v-if="!loading" name="fade">
      <div id="wrapper">
        <sidebar :page="'Home'" :sidebar_data=sidebar_data></sidebar>

        <div class="backdrop" @click="close()"></div>

        <nav :class="'navbar bg-transparent'">
            <div class="container-fluid">
                <div class="navbar-brand bars">
                <h4 class="mb-2 mt-2 ms-2">
                    <span class="fw-bold text-dark">G</span>
                    <span class="text-orange fw-bold">Sort</span>
                </h4>
                </div>

                <span class="navbar-left">
                    <div class="navbar-brand">
                    <span style="display: -webkit-inline-box;">
                        <h4 style="margin-top: 0.2rem">
                        <i class="ph-bell-ringing text-light me-3 nav-icon circle p-2 bg-default"></i>
                        </h4>
                    </span>
                    </div>      
                </span>

                <div class="divider mt-4 d-none"></div>
            </div>
        </nav>

        <floating-nav :page="'SavedProducts'"></floating-nav>

        <div class="body mb-4">
          <div class="container" style="padding: 1rem 2rem 0 2rem">
            <div class="row">
              <div class="col-lg-12 col-md-12">
                <h2 class="text-orange fw-bold" v-if="user.name">Hi, {{ user.name.split(' ')[0] }}!</h2>
              </div>          
            </div> 

            <div class="row mb-4 mt-2" id="search-row">
              <div class="input-group">
                <input id="search" type="text" class="form-control border-end-0" v-model="search" placeholder="Search for any medicine here." required>

                <span class="input-group-text pe-4 ps-4" style="cursor: pointer">
                    <font-awesome-icon id="eye" :icon="['fa', 'search']" />                        
                </span>
              </div>
              <div class="d-flex">
                <small v-if="errors.search" class="text-burgundy">{{ errors.search }}</small>
              </div>
            </div>             

            <div class="row">
              <div class="col-md-12 col-sm-12 mt-2">
                  <div class="d-flex mb-2">
                    <h5 class="text-dark">Saved Products</h5>
                  </div>
              </div>
              
              <div class="col-md-6 col-sm-12 col-lg-6" v-for="(index) in 4" :key="index">
                  <div class="card mb-3" style="cursor: pointer; box-shadow: 0 4px 6px #eee; border-color: #fafafa">
                    <div class="card-body p-3">
                        <div class="d-flex">
                        <img :src="require('../assets/img/item-categories/syrup.jpg')" alt="" class="me-4" style="width: 90px; height: 90px;">
                          <div class="row">
                              <h6 class='text-dark fw-bold mt-2 text-capitalize'>Test {{ index }} </h6>
                              <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit.</p>


                              <div class="d-flex">
                                <div class="d-block ms-auto">
                                  <p class="mb-0 mt-0 fw-bold text-danger">
                                    <font-awesome-icon class="text-danger me-2" :icon="['fas', 'times']" style="margin-top: 3px"></font-awesome-icon> Remove
                                  </p>
                                </div>
                              </div>
                          </div>
                        </div>
                    </div>
                  </div>
              </div>

              <div id="load-more" class="col-md-12 col-sm-12 p-4 pt-1">
                <div class="card bg-transparent bg-gradient border-0">
                  <div class="card-body"></div>
                </div>
              </div>          
            </div>
          </div>
        </div>
      </div>
  </transition>
</template>

<style lang="scss" scoped>
  @import '../assets/scss/layout/_navbar.scss';
  .fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }

  .form-control {
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    line-height: 2.1;
  }
  
  .input-group-text {
    background: transparent;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
  }  
</style>

<script>
  import Sidebar from '../components/AutoSidebar'
  import FloatingNav from '../components/FloatingNav'

  export default {
    name: 'SavedProducts',
    components: {
      Sidebar,
      FloatingNav
    },   
    data() {
      return {
        sidebar_data: [
          {title: 'Home', link: '', if_children: false, icon: 'home', modal: false},
          {title: 'My Account', link: 'profile', if_children: false, icon: 'user', modal: false},
        ],
        errors: {},
        screen: 0,
        stock_type: '',
        type: '',
        chosen_stock: null,
        search: null
      }
    },
    watch: {
      user: function() {
        this.ifNotLoggedIn()
      }
    },
    computed: {
      user () {
        return this.$store.state.user
      },
      loading () {
        return this.$store.state.loading
      }       
    },    
    mounted() {
      this.$store.dispatch("getUser")
    },
    methods: {
      routeTo(value) {
        this.$router.push({name: value});
      },
      ifNotLoggedIn() {
        if( !this.user.name) {
          this.$router.push({name: 'Login'});
        } 
      }
    }    
  }
</script>
